.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  width: 50rem;
}

.saveButton {
  display: none;
}

@media screen {
  .container {
    margin: auto;
    border: 1px solid black;
    padding: 0.5rem;
  }

  .saveButton {
    display: block;
    padding: 0.5rem;
    margin: 1rem auto;

    background: #03dac6;
    border-radius: 0.2rem;
    box-shadow: 0.3rem 0.3rem 0 #ff0166;
    color: #222;
    font-size: 2rem;
    font-weight: 800;
    border: 0;

    transition: box-shadow 200ms ease-in;
  }
  .saveButton:hover {
    box-shadow: 0.5rem 0.5rem 0 #ff0166;
  }
}

@media print {
  @page {
    margin: 2rem;
  }
}
