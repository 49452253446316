.summary {
  margin: 1rem 0;
}

.summary span {
  display: inline-block;
  width: 25%;
}

select {
  width: 98%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  font-weight: normal;
  text-align: left;
  width: 5%;
  border-bottom: solid black 1px;
}

td {
  padding: 0.2rem 0;
}

.sum-row td {
  border-top: solid black 1px;
  font-weight: 600;
}

.header-row th {
  font-weight: 600;
}

.descr {
  width: 10%;
}

.numeric {
  text-align: right;
  text-align-last: right;
}

.numeric input {
  text-align: right;
}

.checkbox {
  text-align: center;
}
