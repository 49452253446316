label {
  width: 10rem;
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

label span {
  display: block;
  width: 9rem;
  font-size: 0.7rem;
}

input {
  display: inline-block;
  font-size: 0.9rem;
  font-family: Arial, Helvetica, sans-serif;
}

label input {
  width: 100%;
  margin-right: 0.2rem;
}

.long-input {
  width: 100%;
  box-sizing: border-box;
}

@media print {
  input {
    border: none;
  }
  label {
    width: 10rem;
  }
  .checkbox {
    text-align: center;
  }

  .print-hidden {
    display: none;
  }
}
